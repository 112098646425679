import React from 'react'
import Head from 'next/head'
import {Image, Text} from '@primer/react-brand'
import {Footer, Ticker, Hero, CallToAction, VideoPlaylist, Spacer} from '../components'

export default function Home() {
  return (
    <>
      <Head>
        <title>GitHub Galaxy 2024</title>
      </Head>

      <main>
        {/* Nav label */}
        <div className="global-nav-label-wrapper">
          <div className="global-nav-label-container">
            <Text className="global-nav-label">Watch GitHub Galaxy on-demand</Text>
          </div>
        </div>

        {/* Hero */}
        <section className="Section">
          <Image
            src="/images/hero/hero-bg.webp"
            srcSet="/images/hero/hero-bg-sm.webp 540w, /images/hero/hero-bg.webp 1278w"
            width={1278}
            alt=""
            className="Hero-visual"
            style={{display: 'block', margin: '88px auto 30px auto'}}
          />

          <div className="Section-container">
            <Hero />
          </div>
        </section>

        {/* Ticker */}
        <Ticker />

        {/* Videos */}
        <section id="sessions" className="Section">
          <div className="Section-container">
            <Spacer narrow="32px" wide="64px" />

            <VideoPlaylist />

            <Spacer narrow="32px" wide="64px" />
          </div>
        </section>

        {/* CTA */}
        <section id="cta" className="Section">
          <div className="Section-container">
            <Spacer narrow="32px" wide="64px" />

            <CallToAction />

            <Spacer narrow="32px" wide="64px" />
          </div>
        </section>

        <Footer />
      </main>
    </>
  )
}
